import React from 'react';

import { useTranslation } from 'react-i18next';

import Fade from 'react-reveal/Fade'

import iphone from '../../img/Services/iphone.png'

import getstarted1 from '../../img/Services/getstarted1.png'
import getstarted2 from '../../img/Services/getstarted2.png'
import getstarted3 from '../../img/Services/getstarted3.png'

function GetStarted() {

  const { t } = useTranslation();

  return (
<div className='bg-[#EDF2EF] z-10 relative'>
    <div className='bg-[#DEE2E1] w-full mt-[120px] xl:mt-[160px]'>
        <div className='mx-auto max-w-[1280px]'>
            <div className='flex flex-col xl:flex-row justify-between pt-[80px] xl:pt-[100px]'>
                <div className='mx-[20px] flex flex-col items-center'>
                    <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] text-center font-medium ml-[10px] xl:ml-0 mr-[10px]'>{t('getstarted1')}</h1>
                    <Fade duration={1500} left><div className='bg-[#EDF2EF] rounded-[20px] xl:w-[703px] h-[160px] mt-[40px]'>
                        <div className='flex flex-col xl:flex-row xl:items-center ml-[20px] xl:ml-[60px] xl:gap-[60px] pt-[20px] xl:pt-[33px]'>
                            <img src={getstarted1} alt="" className='w-[35px] xl:w-[100px] h-[35px] xl:h-[100px]'/>
                            <div className='mt-[10px] xl:mt-0'>
                                <h1 className='manrope text-[#493843] text-[20px] font-medium'>{t('getstarted2')}</h1>
                                <p className='mt-[]textcolor roboto text-[14px] font-light max-w-[290px]'>{t('getstarted3')}</p>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade duration={1500} left><div className='bg-[#EDF2EF] rounded-[20px] xl:w-[703px] h-[160px] mt-[10px]'>
                        <div className='flex flex-col xl:flex-row xl:items-center ml-[20px] xl:ml-[60px] xl:gap-[60px] pt-[20px] xl:pt-[33px]'>
                            <img src={getstarted2} alt="" className='w-[35px] xl:w-[100px] h-[35px] xl:h-[100px]'/>
                            <div className='mt-[10px] xl:mt-0'>
                                <h1 className='manrope text-[#493843] text-[20px] font-medium'>{t('getstarted4')}</h1>
                                <p className='textcolor roboto text-[14px] font-light max-w-[290px]'>{t('getstarted5')}</p>
                            </div>
                        </div>
                    </div></Fade>
                    <Fade duration={1500} left><div className='bg-[#EDF2EF] rounded-[20px] xl:w-[703px] h-[160px] mt-[10px]'>
                        <div className='flex flex-col xl:flex-row xl:items-center ml-[20px] xl:ml-[60px] xl:gap-[60px] pt-[20px] xl:pt-[33px]'>
                            <img src={getstarted3} alt="" className='w-[35px] xl:w-[100px] h-[35px] xl:h-[100px]'/>
                            <div className='mt-[10px] xl:mt-0'>
                                <h1 className='manrope text-[#493843] text-[20px] font-medium'>{t('getstarted6')}</h1>
                                <p className='textcolor roboto text-[14px] font-light max-w-[290px]'>{t('getstarted7')}</p>
                            </div>
                        </div>
                    </div></Fade>
                    <div className='buttonstart mt-[30px]' onClick={() => window.location.href = 'https://user.opti-trade.ltd'}>
                        <p className='text-[16px] roboto text-[#493843]'>{t('getstarted6')}</p>
                    </div>
                </div>
                <Fade duration={1500} right>
                    <div className='relative mx-auto'>
                        <img src={iphone} alt=""/>
                        <div className='flex-col absolute top-[100px] left-[100px] w-full h-full flex'>
                            <p className='text-[#493843] manrope text-[24px] font-medium'>{t('getstarted8')}</p>
                            <p className='text-[#493843] manrope text-[40px] font-medium mt-[-10px]'>{t('getstarted9')}</p>
                            <p className='text-[#493843] manrope text-[20px] font-normal mt-[27px] uppercase'>{t('getstarted10')}</p>
                            <p className='text-[#493843] manrope text-[20px] font-normal mt-[12px] uppercase'>{t('getstarted11')}</p>
                            <p className='text-[#493843] manrope text-[20px] font-normal mt-[12px] uppercase'>{t('getstarted12')}</p>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    </div>
</div>

  );
}

export default GetStarted;