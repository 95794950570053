import React from 'react';
import { useTranslation } from 'react-i18next';

import laptop from '../../img/Home/laptop.png'; 
import vector from '../../img/Home/vectorr.png';

import coin from '../../img/Home/coin.png';
import coin1 from '../../img/Home/2.png';
import coin2 from '../../img/Home/3.png';
import coin3 from '../../img/Home/4.png';
import coin4 from '../../img/Home/5.png';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

function DiscoverOver() {
  
  const { t } = useTranslation();

  return (
    <div className='bg-[#EDF2EF]'>
      <div className='flex flex-col xl:flex-row max-w-[1280px] mx-auto relative'>
        <div className='pt-[120px] xl:pt-[160px] ml-[10px] xl:ml-0 mr-[10px] xl:mr-0 z-10'>
          <Fade left delay={300}>
            <h1 className='text-[32px] md:text-[36px] xl:text-[40px] manrope text-[#493843] xl:w-[469px] font-medium'>
              {t('discover_title')}
            </h1>
          </Fade>
          <Fade left delay={600}>
            <p className='textcolor roboto font-light text-[14px] md:text-[16px] xl:w-[497px] mt-[40px]'>
              {t('discover_description')}
            </p>
          </Fade>
          <Fade left delay={900}>
            <div className='buttonsee mt-[40px]' onClick={() => window.location.href = 'https://user.opti-trade.ltd'}>
              <p className='text-[16px] roboto text-[#493843]'>{t('see_more')}</p>
            </div>
          </Fade>
        </div>
        <Zoom bottom duration={2000}>
          <img src={laptop} alt="" className='mix-blend-darken ml-auto mt-[40px] xl:mt-[80px] z-10 mr-[140px]' />
        </Zoom>
        <img src={vector} alt="" className='absolute right-0 mt-[100px] hidden md:block' />
      </div>
      <div className='max-w-[1280px] mx-auto mt-[80px] z-10 relative'>
        <h1 className='text-[#493843] manrope text-[32px] md:text-[40px] text-center font-medium ml-[10px] xl:ml-0 mr-[10px]'>
          {t('offer_title')}
        </h1>
        <p className='textcolor text-center roboto font-light text-[14px] md:text-[16px] xl:w-[497px] mt-[20px] xl:mx-auto ml-[10px] mr-[10px]'>
          {t('offer_description')}
        </p>
      </div>
      <div className='max-w-[1280px] mx-auto pt-[40px] z-10 relative'>
        <div className='flex flex-wrap gap-x-[150px] gap-y-[20px] justify-center'>
          <Fade bottom delay={300}>
            <div>
              <img src={coin} alt="" className='mx-auto' />
              <h1 className='textcolor text-[16px] md:text-[20px] manrope text-center font-medium mt-[20px]'>
                {t('narrow_spreads')}
              </h1>
              <p className='w-[316px] roboto text-[14px] md:text-[16px] text-center font-light mt-[10px]'>
                {t('narrow_spreads_description')}
              </p>
            </div>
          </Fade>
          <Fade bottom delay={600}>
            <div>
              <img src={coin1} alt="" className='mx-auto' />
              <h1 className='textcolor text-[16px] md:text-[20px] manrope text-center font-medium mt-[20px]'>
                {t('regulated')}
              </h1>
              <p className='w-[316px] roboto text-[14px] md:text-[16px] text-center font-light mt-[10px]'>
                {t('regulated_description')}
              </p>
            </div>
          </Fade>
          <Fade bottom delay={900}>
            <div>
              <img src={coin2} alt="" className='mx-auto' />
              <h1 className='textcolor text-[16px] md:text-[20px] manrope text-center font-medium mt-[20px]'>
                {t('multiple_products')}
              </h1>
              <p className='w-[316px] roboto text-[14px] md:text-[16px] text-center font-light mt-[10px]'>
                {t('multiple_products_description')}
              </p>
            </div>
          </Fade>
          <Fade bottom delay={1200}>
            <div>
              <img src={coin3} alt="" className='mx-auto' />
              <h1 className='textcolor text-[16px] md:text-[20px] manrope text-center font-medium mt-[20px]'>
                {t('api_integration')}
              </h1>
              <p className='w-[316px] roboto text-[14px] md:text-[16px] text-center font-light mt-[10px]'>
                {t('api_integration_description')}
              </p>
            </div>
          </Fade>
          <Fade bottom delay={1500}>
            <div>
              <img src={coin4} alt="" className='mx-auto' />
              <h1 className='textcolor text-[16px] md:text-[20px] manrope text-center font-medium mt-[20px]'>
                {t('service')}
              </h1>
              <p className='w-[316px] roboto text-[14px] md:text-[16px] text-center font-light mt-[10px]'>
                {t('service_description')}
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default DiscoverOver;
